/* reset */
:root { 
 --color-bg : #fbfaf3; 
 --color-point : #ff6633; 
 --vh: 100%;
 } 

* { margin: 0; padding: 0; border: 0; box-sizing: border-box; word-break: keep-all; } 
html { font-size: 62.5%; } /* -webkit-user-select:none; 모바일 드래그 막기 */
body { max-width: 540px; height: 100%; margin: 0 auto; background-color: var(--color-bg); color: var(--color-point); font-family: 'Pretendard', sans-serif; font-size: 1.6rem; font-weight: 500; letter-spacing:-0.01em; line-height: 1.5; box-sizing: border-box; } 
ol,ul,li { list-style: none; } 
table,th,td { border-collapse: collapse; /*border-spacing: 0; */ } 
input, button { font-family:'Pretendard', sans-serif; } 
/* input[type=text]::-ms-clear,
input[type=tel]::-ms-clear { display: none; } */
/*
select { -webkit-appearance: none; -moz-appearance: none; appearance: none; } 
select::-ms-expand { display: none; } IE 10, 11의 네이티브 화살표 숨기기 */
div { outline: none; } 
label { cursor: pointer; } 
label img { pointer-events: none; } 
a { text-decoration: none; color: #000; } 
img { display: block; border: 0; max-width: 100%; width: 100%; } 
button { background-color: transparent; cursor: pointer; } 
p { word-break: keep-all; } 
i { font-style: normal; } 


@media screen and (max-width:480px) { 
 html { font-size: 44.5%; } 
 } 



 
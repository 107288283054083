#mainWrap { animation: draw-ani 1s 1 both; } 
.inner { padding: 8rem 3rem; } 
/* .cont { transition: .3s; } */
.tit-b { font-size: 1.7rem; font-weight: 900; line-height: 1.3; } 
.text-al-le { margin-right: 3.7rem; } 
.text-al-ri { margin-left: auto; text-align: right; } 
.pa-t0 { padding-top: 0 !important; } 
.pa-t3 { padding-bottom: 3rem; } 
.pa-b36 { padding-bottom: 3.6rem; } 
.pa-b0 { padding-bottom: 0; } 
.pa-b4 { padding-bottom: 4rem; } 

.font-16 { font-size: 1.6rem; } 
.font-19 { font-size: 1.9rem; } 
.font-22 { font-size: 2.2rem; } 
.font-25 { font-size: 2.5rem; } 
.font-28 { font-size: 2.8rem; } 

@media screen and (max-width:480px) { 
.inner { padding: 7rem 3rem; } 
 } 

/*main*/
.main-top img { width: 3.7rem; height: 3.7rem; } 
.main-center { position: relative; margin: 2.2rem 0; padding-top: 10.9rem; padding-bottom: 5.1rem; } 
.main-center .img-box { position: relative; padding: 0 2.5rem; } 
.main-center .img-box .img-ab { width: 41.66666666667%; margin-left: .5rem; mix-blend-mode: multiply; } 
.main-center .tit-b i { font-size: 1.6rem; } 
.main-center span { position: absolute; display: inline-block; width: 1.3rem; height: 1.3rem; background-color: #231815; } 
.main-center span:nth-child(1) { top: 0; left: 0; } 
.main-center span:nth-child(2) { top: 0; right: 0; } 
.main-center span:nth-child(3) { bottom: 0; left: 0; } 
.main-center span:nth-child(4) { bottom: 0; right: 0; } 
.main-center .tit-b { margin-top: 8rem; text-align: center; } 

@media screen and (max-width:500px) { 
 
 #mainWrap { width: 100%; height: calc(var(--vh, 1vh) * 100); } 
 #mainWrap .inner { justify-content: space-between; flex-direction: column; display: flex; height: 100%; width: 100%; padding: 7rem 3rem 6rem; } 
 .main-center { width: 100%; height: 100%; margin: 0; padding: 0; } 
 .main-center .img-box { position: absolute; left: 50%; top: 50%; width: 85%; height: auto; margin-top: -4rem; padding: 0; transform: translate(-50%, -50%); } 
 .main-center .tit-b { position: absolute; bottom: 0; left: 50%; width: 100%; margin-top: 0; margin-bottom: 7rem; transform: translateX(-50%); } 
 .main-center span:nth-child(1) { top: 1.5rem; } 
.main-center span:nth-child(2) { top: 1.5rem; } 
.main-center span:nth-child(3) { bottom: 1.5rem; } 
.main-center span:nth-child(4) { bottom: 1.5rem; } 
 } 

 
@media screen and (max-width:480px) { 
.main-center .img-box { width: 72%; } 
.main-center .tit-b { margin-bottom: 5rem; } 
 } 

/*list-tit-wr*/
.list-tit-wr { position: sticky; top: 0; left: 0; background-color: var(--color-bg); padding-top: 8rem; padding-bottom: 2.2rem; z-index: 10; } 
.list-tit-wr .tit-wr { align-items: baseline; width: 100%; margin-bottom: 2rem; padding-bottom: 1.4rem; border-bottom: .5rem solid var(--color-point); } 
.list-tit-wr .tit-wr .tit-b { line-height: 1; font-weight: 800; } 
.list-tit-wr .tit-wr .text-al-le { font-size: 2.7rem; } 
.list-tit-wr .tit-wr .text-al-ri { font-size: 2rem; } 

@media screen and (max-width:480px) { 
 .list-tit-wr { padding-top: 6rem; padding-bottom: 1rem; } 
 .list-tit-wr .tit-wr { margin-bottom: 1rem; } 
 } 

.zoom-btn-wr { position: relative; } 
.zoom-btn-wr .zoom-btn { width: 4rem; height: 4rem; padding: 1rem; color: var(--color-point); font-size: 2.5rem; font-weight: 500; } 
.zoom-btn-wr .zoom-btn-minus { margin-right: 1.5rem; margin-left: auto; } 
.zoom-btn-wr.minus16::after,.zoom-btn-wr.puls22::after { position: absolute; width: 5rem; height: 5rem; background-color: var(--color-bg); opacity: .6; content: ''; } 
.zoom-btn-wr.minus16::after { right: 4rem; } 
.zoom-btn-wr.puls22::after { right: 0rem; } 

@media screen and (max-width:480px) { 
 
 } 
/*홀 리스트 draw-list*/
.draw-list-info { margin-top: 9rem; margin-bottom: 0.65em; text-align: right; } 
.draw-list-info li { display: inline-block; width: 77.70833333333%; text-align: left; } 
.draw-list-info span { display: block; margin-left: 1.5em; margin-bottom: -0.3em; font-size: .735em; } 
.draw-list { font-size: 1.00em; font-weight: 900; } 
.draw-list li { justify-content: flex-end; margin-bottom: 2.6rem; text-align: right; font-size: .8em; } 
.draw-list li span { display: inline-block; width: .46em; height: .46em; border-radius: 100%; margin-left: .9rem; } 
.draw-list li span { width: 1em; height: 1em; } 
.draw-list .ci-1 { border:4px solid #00ccff; } 
.draw-list .ci-2 { border:4px solid #ff9900; } 
.draw-list .ci-3 { border:4px solid #ff0033; } 
.draw-list .ci-4 { border:4px solid #99cc33; } 
@media screen and (max-width:350px) { 
 .draw-list-info li { width: 90.708333%; } 
 } 
/*도면 draw-img-wr*/
.draw-img-wr .draw-img h4 { font-size: 0.9em; } 
.draw-img-wr .draw-img img { transform-origin: top left; transition: .3s; } 
.draw-img-wr .draw-img .img-box { margin-bottom: 2.25rem; overflow: auto; } 
.font-16 .draw-img img { transform: scale(0.9); } 
.font-19 .draw-img img { transform: scale(1); } 
.font-22 .draw-img img { transform: scale(1.1); } 
.font-25 .draw-img img { transform: scale(1.2); } 
.font-28 .draw-img img { transform: scale(1.3); } 

@media screen and (max-width:480px) { 
 
 } 
/*컨텐츠 버튼*/
.cont-btn .inner { padding-top: 0; } 
.cont-btn button { width: 1.8rem; height: 1.8rem; margin-right: 0.9em; } 

@media screen and (max-width:480px) { 
 .cont-btn button { width: 4.5rem; height: 4.5rem; padding: 1.2rem; margin-right: 0.2em; } 
 } 

/*infoWrap 전시소개*/
.data-list-wr { display: grid; } 
.data-list-wr .data-list { align-items: flex-start; } 
.data-list-wr .data-list:not(:first-child) { margin-top: 3.5rem; } 
.data-list-wr .data-list .num-ci { width: 10.83333333333%; text-align: center; } 
.data-list-wr .data-list .num-ci span { display: inline-block; width: 2rem; height: 2rem; border-radius: 100%; background-color: #00ccff; color: #fff; font-size: 1.5rem; text-align: center; line-height: 2.1rem; } 
.data-list-wr .data-list img { width: 16.66666666667%; } 
.data-list-wr .data-list .list-tit { width: 72.5%; padding-left: 1.8rem; } 
.data-list-wr .data-list .list-tit .tit-b { margin-bottom: .2em; line-height: 1.2; font-size: 1.26em; font-weight: 800; } 
.data-list-wr .data-list .list-tit .tit-b i { font-size:0.7em; font-weight: 800; } 
.data-list-wr .data-list .list-tit .tit-b i.badge { display: inline-block; padding: .1em .5em; margin-left: .3em; background-color: var(--color-point); border-radius: 2rem; color: #fff; font-size: .28em; font-weight: 300; } 
.data-list-wr .data-list .list-tit p { font-size: 0.85em; font-weight: 500; } 
.data-list-wr .data-list .list-tit p.add { display: none; } 
.data-list-wr .data-list .list-tit p.add.on { display: block; } 
.data-list-wr .data-list.add { display: none; } 
.data-list-wr .data-list.add.on { display: flex; } 
.data-list-wr .data-list.add.on { margin-top: 1.7rem; } 
.data-list-wr .data-list .list-tit .tit-b i.i-txt { font-size:0.85em; font-weight: 500; } 
.data-list-wr .data-list br { display: none; } 
.data-list-wr .data-list br.on { display: block; } 
@media screen and (max-width:480px) { 
 
 } 


#infoWrap { position: relative; } 
#infoWrap .inner { padding-top: 0; } 
#infoWrap .cont { font-weight: 700; } 
#infoWrap .cont p { font-size: 1.37em; } 
#infoWrap .font-25 .cont p,
#infoWrap .font-28 .cont p { line-height: 1.2; } 

/*전시장도면 버튼*/
.draw-img .img-btn { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 1; } 

.draw-img .img-btn-seda1 { width: 11%; height: 27%; margin-top: -29%; margin-left: 14%; } 
.draw-img .img-btn-kim1 { width: 17%; height: 3%; margin-top: -51%; margin-left: -2%; } 
.draw-img .img-btn-kim2 { bottom: -9%; top: auto; width: 41%; height: 19%; margin-left: -9%; } 
.draw-img .img-btn-biju1 { width: 20%; height: 11%; margin-top: -33%; margin-left: -12%; } 
.draw-img .img-btn-biju2 { width: 5%; height: 3%; margin-top: 57%; margin-left: 25%; } 
.draw-img .img-btn-hands1 { width: 8%; height: 8%; margin-top: -66%; margin-left: -7%; } 
.draw-img .img-btn-hands2 { width: 22%; height: 10%; margin-top: -16%; margin-left: -4%; } 
.draw-img .img-btn-hands3 { width: 5%; height: 3%; margin-top: 63%; margin-left: 25%; } 
.draw-img .img-btn-hands4 { width: 5%; height: 3%; margin-top: 55%; margin-left: 32%; } 

.font-16 .draw-img .img-btn-seda1 { margin-top: -33%; margin-left: 8%; } 
.font-16 .draw-img .img-btn-kim1 { margin-top: -53%; margin-left: -7%; } 
.font-16 .draw-img .img-btn-kim2 { bottom: 0%; margin-left: -13%; } 
.font-16 .draw-img .img-btn-biju1 { margin-top: -37%; margin-left: -16%; } 
.font-16 .draw-img .img-btn-biju2 { margin-top: 43%; margin-left: 18%; } 
.font-16 .draw-img .img-btn-hands1 { margin-top: -67%; margin-left: -12%; } 
.font-16 .draw-img .img-btn-hands2 { width: 22%; height: 8%; margin-top: -22%; margin-left: -12%; } 
.font-16 .draw-img .img-btn-hands3 { margin-top: 49%; margin-left: 18%; } 
.font-16 .draw-img .img-btn-hands4 { margin-top: 42%; margin-left: 25%; } 

.font-22 .draw-img .img-btn-seda1 { width: 15%; height: 29%; margin-top: -24%; margin-left: 19%; } 
.font-22 .draw-img .img-btn-kim1 { margin-top: -48%; margin-left: 2%; } 
.font-22 .draw-img .img-btn-kim2 { bottom: -18%; width: 45%; margin-left: -5%; } 
.font-22 .draw-img .img-btn-biju1 { width: 23%; height: 13%; margin-top: -28%; margin-left: -8%; } 
.font-22 .draw-img .img-btn-biju2 { margin-top: 71%; margin-left: 33%; } 
.font-22 .draw-img .img-btn-hands1 { margin-top: -65%; margin-left: -4%; } 
.font-22 .draw-img .img-btn-hands2 { margin-top: -10%; } 
.font-22 .draw-img .img-btn-hands3 { margin-top: 77%; margin-left: 33%; } 
.font-22 .draw-img .img-btn-hands4 { margin-top: 69%; margin-left: 41%; } 


#drawWrap { animation: draw-ani 1s .1s 1 both; } 
#drawWrap .draw-img { position: relative; margin-bottom: 10rem; } 
#drawWrap .draw-img .img-box { position: relative; } 
#drawWrap .artist-tit h3 { font-size: 1.75em; font-weight: 800; line-height: 1.2; } 
#drawWrap .artist-tit span { display: block; margin-bottom: .35em; font-size: 0.9em; } 

#drawWrap.draw-seda .artist-tit { position: absolute; right: 0; bottom: 1em; text-align: right; } 
#drawWrap.draw-seda .draw-img-wr .draw-img .img-box img { height: 60rem; width: auto; } 
#drawWrap.draw-seda .draw-list li:nth-child(1) { display: none; } 

#drawWrap.draw-kim .artist-tit { position: absolute; top: 50%; right: 0; text-align: right; transform: translateY(-50%); } 
#drawWrap.draw-kim .data-list-wr .data-list .num-ci span { background-color: #ff9900; } 
#drawWrap.draw-kim .draw-list li:nth-child(2) { display: none; } 

#drawWrap.draw-biju .data-list-wr .data-list .num-ci span { background-color: #ff0033; } 
#drawWrap.draw-biju .artist-tit { position: absolute; top: 50%; left: 0; transform: translateY(-2em); } 
#drawWrap.draw-biju .draw-list li:nth-child(3) { display: none; } 

#drawWrap.draw-hands .data-list-wr .data-list .num-ci span { background-color: #99cc33; } 
#drawWrap.draw-hands .artist-tit { position: absolute; top: 50%; left: 0; transform: translateY(6.5em); } 
#drawWrap.draw-hands .draw-list li:nth-child(4) { display: none; } 
#drawWrap.draw-hands .data-list-wr .data-list .list-tit .size-on { margin-left: 3.24em; } 
#drawWrap.draw-hands .data-list-wr .data-list i { display: block; font-weight: 800; } 

@keyframes draw-ani { 
 0% { opacity: 0; transform: translateY(2rem); } 
 100% { opacity: 1; transform: translateY(0); } 
 } 

@media screen and (max-width:480px) { 
 
 } 















/**/